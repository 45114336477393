

<div class="content-application">

    <app-header></app-header>

    <div class="content-div-app">

      <app-list-dedicaces></app-list-dedicaces>

      <!-- <app-separator-top></app-separator-top> -->
      <!-- <app-flash-pub></app-flash-pub> -->

      <div class="row banner-pub">
        <div class="col-lg-12">
          <img class="d-block w-100" src="assets/banner/banner_1.gif" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa">
        </div>
      </div>

      <app-home-page-first></app-home-page-first>

    </div>

    <app-footer></app-footer>

</div>
