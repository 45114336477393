import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-programmation',
  templateUrl: './programmation.component.html',
  styleUrls: ['./programmation.component.scss']
})
export class ProgrammationComponent implements OnInit, AfterViewInit {

  @Input() lesProgrammes = []
  indexSelected = 4;


  constructor() { }

  ngOnInit(): void {
    

    

  }

  ngAfterViewInit(): void {

    const d = new Date();
    const weekday = new Array(7);
    weekday[0] = {day: "dimanche", index: 6 };
    weekday[1] = {day: "lundi", index: 0 };
    weekday[2] = {day: "mardi", index: 1 };
    weekday[3] = {day: "mercredi", index: 2 };
    weekday[4] = {day: "jeudi", index: 3 };
    weekday[5] = {day: "vendredi", index: 4 };
    weekday[6] = {day: "samedi", index: 5 };
    const n = weekday[d.getDay()];

    setTimeout(() => {
      this.indexSelected = n.index;
    }, 1000);

    
  }

  onChangeEvent(event) {
    console.log('Event event ===> ', event)
    this.indexSelected = event;
  }

}
