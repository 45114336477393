import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../../services/global/global.service';
import { BackendDirectService } from '../../services/backend-direct/backend.service';
import { EventService } from '../../services/events/events.service';
import { BackendService } from '../../services/backend/backend.service';


@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  videosArray = [];
  retourMessage = { ok: null, ko: null };

  constructor(
    private globalService: GlobalService,
    private backendDirectService: BackendDirectService,
    private eventService: EventService,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    this.displayPage();
  }

  
  displayPage() {

    this.backendService.get( `/reglages/video-youtube` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ', resultat );
         if ( resultat && resultat.items ) {
            this.videosArray = resultat.items;
         } else {
          this.videosArray = [0];
         }
         
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

}
