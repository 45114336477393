

<div class="content-application">

  <app-header></app-header>


  <div class="content-div-app">

      <app-list-dedicaces></app-list-dedicaces>

      <div class="row banner-pub">
          <div class="col-lg-12">
              <img class="d-block w-100" src="assets/banner/banner_1.gif" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa">
          </div>
      </div>

      <div class="">
          <app-content-home></app-content-home>
      </div>


  </div>


  <app-footer></app-footer>

</div>
