import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../../services/global/global.service';
import { BackendDirectService } from '../../services/backend-direct/backend.service';
import { EventService } from '../../services/events/events.service';
import { BackendService } from '../../services/backend/backend.service';
import { AudioSService } from '../../services/audio-s/audio-s.service';
import { LoaderService } from '../../services/loader/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nuktwa-prod',
  templateUrl: './nuktwa-prod.component.html',
  styleUrls: ['./nuktwa-prod.component.scss']
})
export class NuktwaProdComponent implements OnInit {

  retourMessage = { ok: null, ko: null };
  nosVoixOff = [];

  typeRead = 'nuktwaProduction';
  identifiantRead = 'nuktwaProduction';
  playlist_playing_encour : any;

  playlistSubscription: Subscription
  
  last_indice = 0;

  constructor(
    private globalService: GlobalService,
    private audioSService: AudioSService,
    private eventService: EventService,
    private backendService: BackendService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.displayProduction()

  }

  displayProduction() {
    this.backendService.get( `/reglages/production` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat displayProduction', resultat );
         this.nosVoixOff = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      console.log( 'ko :: ', error)
    })
  }

  changeStatus(resultat) {
    resultat.ckecked = !resultat.ckecked
  }



  
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.observableAudio()
      this.isPlayAudioEncours()
    }, 1000);
  }

  observableAudio(){

    this.playlistSubscription = this.audioSService.getPlayerStatus().subscribe(status => {
      
      console.log('Observable lecture audio ', status )

      if ( this.audioSService.getLectureEnCours().type === this.typeRead && this.audioSService.getLectureEnCours().identifiant === this.identifiantRead ) {

        // this.nosVoixOff = this.audioSService.getPlaylist();
        this.eventService.setPlayingSelect(
          this.audioSService.getCurrentPlaying(),
          this.audioSService.getCurrentPlaying().index,
          status
        );

        switch (status) {
          case 'playing':
            this.last_indice = this.playlist_playing_encour.index
            this.nosVoixOff[this.playlist_playing_encour.index].playing = true
            this.nosVoixOff[this.playlist_playing_encour.index].paused = false
            this.playlist_playing_encour = this.nosVoixOff[this.playlist_playing_encour.index];
            // this.displayProgress();
            this.loaderService.hide();
            break;
          case 'paused':
            if( this.nosVoixOff ) {
              if ( this.last_indice === this.audioSService.getLectureEnCours().index ) {
                this.nosVoixOff[this.playlist_playing_encour.index].playing = false
                this.nosVoixOff[this.playlist_playing_encour.index].paused = true
              } else {
                this.nosVoixOff[this.playlist_playing_encour.index].playing = false
                this.nosVoixOff[this.playlist_playing_encour.index].paused = false
              }
            }
              
            break;
          case 'waiting':
            this.nosVoixOff[this.playlist_playing_encour.index].playing = true
            this.nosVoixOff[this.playlist_playing_encour.index].paused = false
            break;
          case 'ended':
            this.nosVoixOff[this.playlist_playing_encour.index].playing = false
            this.nosVoixOff[this.playlist_playing_encour.index].paused = false
            this.nextPlay()
            break;
          default:
            if( this.nosVoixOff )
              this.nosVoixOff[this.playlist_playing_encour.index].playing = false
              this.nosVoixOff[this.playlist_playing_encour.index].paused = false
            break;
        }
      }
      
    })

  }


  isPlayAudioEncours() {
    if (this.audioSService.isPlaying() &&
        this.audioSService.getLectureEnCours().type === this.typeRead &&
        this.audioSService.getLectureEnCours().identifiant === this.identifiantRead ) {
          // this.nosVoixOff[this.audioSService.getLectureEnCours().index].playing = true
          // this.nosVoixOff[this.audioSService.getLectureEnCours().index].paused = false

          this.playlist_playing_encour = this.nosVoixOff[this.audioSService.getLectureEnCours().index];

          this.nosVoixOff = this.audioSService.getPlaylist();
    } else if ( this.audioSService.isPlaying() ) {
      this.playlist_playing_encour = this.audioSService.getCurrentPlaying();
    }
  }

  async playAudioAndInitPlaylist( audio , index){
    if ( audio ) {
      this.audioSService.setPlaylist( this.nosVoixOff );
      this.playAudio( audio , index);
    }
  }

  setPauseOther( index ) {
    this.nosVoixOff.forEach((elt, ind) => {
      elt.playing = index === ind ? true : false;
    })
  }

  async playAudio( audio , index){

    console.log('playAudio lecture ::: ' , audio , index )

    if( audio ) {
      const url = audio.url_audio ? audio.url_audio : audio.src ? audio.src : audio.url;
      this.playlist_playing_encour = audio;
      this.playlist_playing_encour.index = index;
      this.playlist_playing_encour.type = this.typeRead;

      if( audio.playing ) {
        this.audioSService.pauseAudio()
      } else {

        this.loaderService.show()
        this.setPauseOther( index )

        console.log('Lecteur lecture ::: ' , audio )
        if( this.audioSService.isPlaying() ) {
          this.audioSService.closePlayer()
        }
        this.audioSService.setAudio(url)
        // this.audioSService.setTypePlaying( 'playlist' )
        this.audioSService.setLectureEnCours( this.typeRead, this.identifiantRead , index );
      }

    }

  }


  nextPlay(){
    console.log( 'nextPlay ' , this.playlist_playing_encour )
    if( this.playlist_playing_encour && ((this.playlist_playing_encour.index + 1) < this.nosVoixOff.length ) ) {

      console.log( 'nextPlay If ' , this.playlist_playing_encour )
      this.nosVoixOff[this.playlist_playing_encour.index].playing = false
      this.nosVoixOff[this.playlist_playing_encour.index].stopped = true
      // this.playlist_playing_encour = this.nosVoixOff[this.playlist_playing_encour.index + 1];
      this.audioSService.majStatusPlaylist( this.playlist_playing_encour.index );
      this.playAudio( this.nosVoixOff[this.playlist_playing_encour.index + 1], this.playlist_playing_encour.index + 1 );
      
    } else {
      console.log( 'nextPlay Else ' , this.playlist_playing_encour )
      this.playlist_playing_encour.index = 0
    }
  }

  prevPlay(){
    if( this.playlist_playing_encour && ((this.playlist_playing_encour.index - 1) >= 0 ) ) {

      console.log( 'play prev ******* ')
      this.nosVoixOff[this.playlist_playing_encour.index].playing = false
      this.nosVoixOff[this.playlist_playing_encour.index].stopped = true
      console.log( 'play prev ******* ' , this.nosVoixOff[this.playlist_playing_encour.index - 1] , this.playlist_playing_encour.index - 1 )
      // this.playlist_playing_encour = this.nosVoixOff[this.playlist_playing_encour.index - 1];
      this.audioSService.majStatusPlaylist( this.playlist_playing_encour.index );
      this.playAudio( this.nosVoixOff[this.playlist_playing_encour.index - 1], this.playlist_playing_encour.index - 1 );
      
    } else {
      
      this.playlist_playing_encour.index = 0
    }
  }


  isLastPlaying() {
    return this.playlist_playing_encour.index === this.nosVoixOff.length - 1;
  }

  playInBottom() {
    this.playAudio( this.playlist_playing_encour , this.playlist_playing_encour.index)
  }

  payWithImage() {
    if ( this.playlist_playing_encour ) {
      this.playInBottom()
    } else if ( this.nosVoixOff.length > 0 ) {
      this.playAudio( this.nosVoixOff[0], 0 );
    }
  }

}
