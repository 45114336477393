import { Component, OnInit } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-separator-top',
  templateUrl: './separator-top.component.html',
  styleUrls: ['./separator-top.component.scss']
})
export class SeparatorTopComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  openPage( page ) {
    this.router.navigate([ page ]);
  }

}
