import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';


import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SeparatorTopComponent } from './separator-top/separator-top.component';
import { PlayAudioComponent } from './play-audio/play-audio.component';
import { ApplicationsComponent } from './applications/applications.component';
import { VideosComponent } from './videos/videos.component';
import { NuktwaProdComponent } from './nuktwa-prod/nuktwa-prod.component';
import { ProgrammeComponent } from './programme/programme.component';
import { NuktwaEmpireComponent } from './nuktwa-empire/nuktwa-empire.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CoranListComponent } from './coran-list/coran-list.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SeparatorTopComponent,
    PlayAudioComponent,
    ApplicationsComponent,
    VideosComponent,
    NuktwaProdComponent,
    ProgrammeComponent,
    NuktwaEmpireComponent,
    NotFoundComponent,
    CoranListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule
  ]
})
export class PagesModule { }
