import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flash-pub',
  templateUrl: './flash-pub.component.html',
  styleUrls: ['./flash-pub.component.scss']
})
export class FlashPubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
