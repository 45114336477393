

<div class="row nos-podscast">

    <div class="col-lg-8 col-md-12">

        <div class="row">
            <div class="col-md-6" *ngFor="let pdc of arrayPodcats" >

                <div class="card-pod row" (click)="readPlayList(pdc)" >


                        <div class="col-4">
                            <img alt="" class="avatar-content" width="80" height="80" 
                                [src]="pdc.image ? pdc.image : pdc.photo"
                            />
                        </div>

                        <div class="col-8">
                            <div class="row">
                                <div class="col-12 colorRouge text-14">
                                    <span *ngIf="pdc.txt_nom" class=" text-bold "> {{ pdc.txt_nom}} </span>
                                    <span *ngIf="pdc.nom_fr" class=""> {{pdc.nom_fr}} </span>
                                </div>
                            </div>
                            
                            <!-- <div class="row">
                                <div class="col-12">
                                    <span class=" cursor btn-play-audio" > Play </span> 
                                </div>
                            </div> -->
                        </div>
                        
 

                    

                </div>

            </div>
        </div>

    </div>

    <div class="col-lg-4  d-none d-md-none d-lg-block">
       <!-- <img src="assets/imgs/music-bro.svg" class="image-music" /> -->
       <img src="assets/imgs/podcast.svg" class="image-music" />
    </div>

</div>
