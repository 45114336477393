

<div class="content-application">

    <app-header></app-header>

    


    <div class="content-div-app">
        <app-separator-top></app-separator-top>
        
        <div class="contenu-des-pages contenu-home">

            <div class="row espage-in-title">
                <div class="col-md-12 text-h3 text-bold colorRouge">
                    Nuktwa Fm sur le Mobile
                </div>
            </div>

            <div class="row">

                <div class="col-md-8 ">
                    <img src="assets/imgs/app.png" class="w-100 h-100"  />
                </div>

                <div class="col-md-4 ">

                    <div class="row">
                        <div class="col-12 text-center">
                            <img src="assets/imgs/icon-nuktwafm.png" class="background-image max-img-height-200" />
                        </div>
                    </div>

                    <div class="row espace-top">

                        <div class="col-12 margin-bottom-10">
                            <div class="text-store text-12 text-bold cursor" (click)="openStore( 'ios' )" > 
                                <img src="assets/imgs/apple.png" class=""  />
                                App Store
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="text-store text-12 text-bold cursor" (click)="openStore( 'android' )" > 
                                <img src="assets/imgs/play-store.png" class=""  />
                                Google Play
                            </div>
                        </div>

                    </div>
                    
                </div>

            </div>
            
        </div>

    </div>

    <app-footer></app-footer>
    
</div>
