import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global/global.service';

import { BackendDirectService } from '../../services/backend-direct/backend.service';
import { EventService } from '../../services/events/events.service';
import { BackendService } from '../../services/backend/backend.service';
import { AudioSService } from '../../services/audio-s/audio-s.service';
import { LoaderService } from '../../services/loader/loader.service';


@Component({
  selector: 'app-home-page-first',
  templateUrl: './home-page-first.component.html',
  styleUrls: ['./home-page-first.component.scss']
})
export class HomePageFirstComponent implements OnInit {

  arrayFlashInfo = [];
  playlist_playing_encour : any;
  thePlayList = [];
  typeRead = 'homePage';
  identifiantRead = 'enCeMoment';
  imageShow = "assets/imgs/music-bro.svg"

  retourMessage = { ok: null, ko: null };

  constructor(
    private globalService: GlobalService,
    private audioSService: AudioSService,
    private eventService: EventService,
    private backendService: BackendService
  ) { 
    this.eventService.playingSelected.subscribe( (value) => {
      if ( this.audioSService.getLectureEnCours().type === this.typeRead && this.audioSService.getLectureEnCours().identifiant === this.identifiantRead ) {
        this.playlist_playing_encour = this.audioSService.getCurrentPlaying();
        this.thePlayList = this.audioSService.getPlaylist();
      }
    })
  }

  ngOnInit(): void {
    this.arrayFlashInfo = this.globalService.initFlashInfo();
    this.displayEnCeMoment();
  }


  displayEnCeMoment(){
    this.backendService.get( `/reglages/en-ce-moment` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        console.log( 'resultat en-ce-moment', resultat );
        this.thePlayList = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      console.log( 'ko :: ', error)
    })
  }

}
