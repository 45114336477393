import { Component } from '@angular/core';

import { EventService } from './services/events/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nuktwa2021';

  constructor(
    private eventService: EventService
  ) { }

  openDedicace(){
    this.eventService.onOpendedicace()
  }

}
