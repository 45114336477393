import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../../services/global/global.service';
import { BackendService } from '../../services/backend/backend.service';
import { EventService } from '../../services/events/events.service';


@Component({
  selector: 'app-list-dedicaces',
  templateUrl: './list-dedicaces.component.html',
  styleUrls: ['./list-dedicaces.component.scss']
})
export class ListDedicacesComponent implements OnInit {

  retourMessage = { ok: null, ko: null };
  arrayFlashInfo = [];

  constructor(
    private globalService: GlobalService,
    private backendService: BackendService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getDedicacesListInPage();
  }

  getDedicacesListInPage() {

    this.backendService.get( `/dedicaces/list` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat arrayFlashInfo Dedicaces ', resultat );
         this.arrayFlashInfo = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }

  openDedicacePage(){
    console.log('click open ')
   //this.eventService.onOpendedicace()
  }
  

}
