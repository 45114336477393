

<div class="content-application">

    <app-header></app-header>


    <div class="content-div-app">
        <app-separator-top></app-separator-top>
        
        <div class="contenu-des-pages contenu-home">

            <div class="row espage-in-title">
                <div class="col-md-12 text-h3 text-bold colorRouge text-center">
                    <img class="d-block nuktwa-empire-img" src="../../assets/empire/NuktwaEmpire.png" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa" />
                </div>
            </div>




            <div class="row margin-div-bottom-10 margin-div-top-10">
                <div class="col-md-6 detail-div-text">
                    <img class="d-block nuktwa-empire-img" src="../../assets/empire/nuktwafm.png" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa" />
                    <p>
                        Nuktwa-FM , une radio comorienne d'actualités, diffusée mondialement en comorien et en français.<br />
                        Ecoutez Nuktwa Fm dans le Web et sur Mobile Android et iOS. <br />
                        Nuktwa-FM propose à ces auditeurs des rendez-vous d'informations et des magazines sur les îles Comores.
                    </p>
                </div>
                <div class="col-md-6 detail-div-text">
                    <img class="d-block nuktwa-empire-img" src="../../assets/empire/NuktwaProduction.png" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa" />
                    <p>
                        Nuktwa Prod avec vous et pour vous. <br />
                        Avec vous, nous imaginons la campagne publicitaire qui fera mouche et saura piquer votre cible. Nous démarquons 
                        vos spots publicitaires pour faire de vos campagnes institutionnelle, promotionnelle ou événementielle, la clé de votre succès. <br />
                        Entourés de nos équipes créatives, partez à la conquête de votre cœur de cible. <br /><br />
                        Contactez Nuktwa aux Comores (00269) 334 30 42 , en France (00336) 12 13 01 32 ou par email info@nuktwafm.com  <br />
                    </p>
                </div>
            </div>

            <div class="row margin-div-bottom-10 margin-div-top-10">
                <div class="col-md-6 detail-div-text">
                  <img class="d-block nuktwa-empire-img" src="../../assets/empire/NuktwaLocation.png" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa" />
                  <p>
                      Nuktwa Location, votre location de sonorisation au meilleur prix défiant toute concurence. <br />
                      Un événement ....? Contactez Nuktwa aux Comores (00269) 334 30 42 , en France (00336) 12 13 01 32 ou par email info@nuktwafm.com
                  </p>
                </div>
                <div class="col-md-6 detail-div-text">
                  <img class="d-block nuktwa-empire-img" src="../../assets/empire/NuktwaConnect.png" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa" />
                  <p>
                      Un espace unique à Mkazi Comores.  <br />
                      Nuktwa Connect prpose une connection internet, impression, copies, réliures, numérisation , Word et Excel , tarif à la minute.
                  </p>
                </div>
            </div>


            <div class="row margin-div-bottom-10 margin-div-top-10">
                <div class="col-md-6 detail-div-text">
                    <img class="d-block nuktwa-empire-img" src="../../assets/empire/NyktwaHumanitaire.png" alt="Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores - Mkazi, Marseille, Web et Mobile , Radio nuktwa Ha Nuktwa" />
                    <p>
                        <strong>Nuktwa Humanitaire</strong>  <br />
                        Nombreux familles vivent dans la pauvreté et cela nous touche, c’est pourquoi L’équipe Nuktwa S’immobilise et lance Nuktwa Humanitaire.
                    </p>
                </div>
                  
            
            </div>


            
        </div>

    </div>

    <app-footer></app-footer>
    
</div>
