

<div class="contenu-home">


    <app-flash-infos [arrayFlashInfos] = "arrayFlashInfo" ></app-flash-infos>

    <app-podcast [arrayPodcats] = "listeCoran" [isCoran]="isCoran" ></app-podcast>


</div>