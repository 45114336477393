<div class="row d-md-block d-xl-block ">
    <div class="col-md-12">
        <div class="onoffswitch3">
            <input type="checkbox" name="onoffswitch3" class="onoffswitch3-checkbox" id="myonoffswitch3" checked>
            <label class="onoffswitch3-label" for="myonoffswitch3">
                <span class="onoffswitch3-inner">
                    <span class="onoffswitch3-active">
                        <marquee class="scroll-text" >
                            <span *ngFor="let flinf of arrayFlashInfos" class="">
                                &nbsp; <i class="fa fa-forward" aria-hidden="true"></i> &nbsp; 
                                {{flinf.text}}
                            </span>
                        </marquee>
                        <span class="onoffswitch3-switch">Flash info &nbsp;<span class="glyphicon glyphicon-remove"></span></span>
                    </span>
                    <span class="onoffswitch3-inactive"><span class="onoffswitch3-switch">AFFICHER </span></span>
                </span>
            </label>
        </div>
    </div>
</div>