import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-flash-infos',
  templateUrl: './flash-infos.component.html',
  styleUrls: ['./flash-infos.component.scss']
})
export class FlashInfosComponent implements OnInit {

  @Input() arrayFlashInfos = []
  constructor() { }

  ngOnInit(): void {
  }

}
