
<div class="row">
    <div class="fab-container d-none d-md-block d-xl-block " >
        <button mat-fab class="fab-toggler" (click)="openDedicace()" id="addClass" >
            <img src="assets/imgs/icon/adddedicace.png" width="30" />
        </button>
    </div>
</div>

<app-add-dedicaces> </app-add-dedicaces>

<router-outlet></router-outlet>

<app-loader>  </app-loader>
