import { BrowserModule } from '@angular/platform-browser';
import { NgModule , APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { LOCALE_ID } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {  PagesModule } from './pages/pages.module';
import { ModalsModule } from './modals/modals.module';
import { ServicesModule } from './services/services.module';
import { SharedModule } from './shared/shared.module';

import { JwtInterceptor } from './auth-helper/jwt.interceptor';
import { ErrorInterceptor } from './auth-helper/error.interceptor';
import { BackendService } from './services/backend/backend.service';

export function loadConfigurations(backService: BackendService) {
  return () => backService.load();
}

import { MaterialAngModule } from './shared/matmodule/materialang.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialAngModule,


    SharedModule,
    PagesModule,
    ModalsModule,
    ServicesModule
  ],
  providers: [
    BackendService, 
    { provide: APP_INITIALIZER, useFactory: loadConfigurations, deps: [BackendService], multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' } ,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
