import { Component, OnInit } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCollapsed = false
  pageSelected = "accueil"
  constructor(
    private router: Router,
  ) { 

    this.router.events.forEach((val) => {
      // see also 
      
      if(val instanceof NavigationEnd) {
        console.log("val ==> ", val) 
        const arraySplit = val.urlAfterRedirects.split("/");
        arraySplit.shift()
        console.log('Array split ', arraySplit);

        this.pageSelected = arraySplit[0];

      }

    });

  }

  ngOnInit(): void {
  }


  openPage( page ) {
    this.pageSelected = page;
    this.router.navigate([ page ]);
  }

}
