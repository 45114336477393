<nav class="navbar navbar-expand-lg navbar-dark content-header  fixed-top">


    <div class="navbar-brand cursor nav-logo-bg" (click)="openPage('/accueil')">
        <img src="assets/imgs/nuktwafm.png" class="" width="200px" />
    </div>

    <button class="navbar-toggler btn-collapse" type="button" (click)="isCollapsed = !isCollapsed"
        aria-controls="navbarResponsive" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
        <i class="material-icons">menu</i>
    </button>


    <div [ngbCollapse]="!isCollapsed" class="collapse navbar-collapse" id="navbarResponsive">

        <ul class="navbar-nav mr-auto">

            <li class="nav-item active" [ngClass]="pageSelected === 'accueil' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/accueil')" >
                    Accueil
                </a>
            </li>

            <li class="nav-item active" [ngClass]="pageSelected === 'nos-applications' ? 'menu-selected' : ''">
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/nos-applications')" >
                    Applications
                </a>
            </li>

            <li class="nav-item active" [ngClass]="pageSelected === 'podcast' || pageSelected === 'play-audio' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/play-audio/nos-podcast/audios')" >
                    Podcast
                </a>
            </li>

            <!--<li class="nav-item active" [ngClass]="pageSelected === 'nos-videos' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/nos-videos')" >
                    Vidéos
                </a>
            </li>-->

            <li class="nav-item active" [ngClass]="pageSelected === 'nuktwa-production' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/nuktwa-production')" >
                    Nuktwa Production
                </a>
            </li>

            <li class="nav-item active" [ngClass]="pageSelected === 'programme' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/programme')" >
                    Programme
                </a>
            </li>

            <li class="nav-item active" [ngClass]="pageSelected === 'nuktwa-empire' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/nuktwa-empire')" >
                    Nuktwa Empire
                </a>
            </li>

            <li class="nav-item active" [ngClass]="pageSelected === 'coran' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/coran')" >
                    Coran
                </a>
            </li>

        </ul>

        <ul class="navbar-nav ml-auto">

           <!-- <li class="nav-item active  d-lg-none d-xl-none " [ngClass]="pageSelected === 'nous-contacter' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor " (click)="openPage('/expedier-ou-recevoir-un-colis')" >
                    Contact
                </a>
            </li> -->

            <li class="nav-item active">
                <app-lecteur></app-lecteur>
            </li>


           <!-- <li class="nav-item active d-none d-lg-block " [ngClass]="pageSelected === 'nous-contacter' ? 'menu-selected' : ''" >
                <a class="nav-link waves-effect waves-light cursor text-center" (click)="openPage('/expedier-ou-recevoir-un-colis')" >
                    <span class="material-icons"> email </span> <br />
                    Contact
                </a>
            </li> -->

        </ul>


    </div>


  </nav>
