import { Injectable } from '@angular/core';
import { Output , EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class EventService {

  @Output() majTokenUser = new EventEmitter();
  
  @Output() playingSelected = new EventEmitter();
  playingSelect = false;

  @Output() pageDedicaced = new EventEmitter();
  pageDedi

  articleSelected = null


  constructor() { }

  setPlayingSelect(play , index , status ){
    this.playingSelect = play
    this.playingSelected.emit( {play : this.playingSelect , index: index , status : status })
  }


  onOpendedicace( ){
    this.pageDedicaced.emit()
  }

  onMajToken(){
    this.majTokenUser.emit()
  }

  
}
