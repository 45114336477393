import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute } from '@angular/router';

import { GlobalService } from '../../services/global/global.service';
import { EventService } from '../../services/events/events.service';
import { BackendService } from '../../services/backend/backend.service';
import { AudioSService } from '../../services/audio-s/audio-s.service';






@Component({
  selector: 'app-play-audio',
  templateUrl: './play-audio.component.html',
  styleUrls: ['./play-audio.component.scss']
})
export class PlayAudioComponent implements OnInit {

  thePlayList = [];
  firstElement;
  typeRead = 'coran';
  identifiantRead;
  imageShow = "assets/imgs/podcast.svg"

  playlist_playing_encour : any;

  retourMessage = { ok: null, ko: null };

  constructor(
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private audioSService: AudioSService,
    private eventService: EventService,
    private backendService: BackendService
  ) { 
    this.eventService.playingSelected.subscribe( (value) => {
      if ( this.audioSService.getLectureEnCours().type === this.typeRead && this.audioSService.getLectureEnCours().identifiant === this.identifiantRead ) {
        this.playlist_playing_encour = this.audioSService.getCurrentPlaying();
        this.thePlayList = this.audioSService.getPlaylist();
      }
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      console.log('Param router :: ' , routeParams)

      this.typeRead = routeParams.type;
      this.identifiantRead = routeParams.id;
      if (routeParams.type === 'coran') {
        this.displayPageCoran(routeParams.id);
      } else {
        if ( routeParams.id === 'audios' ) {
          this.displayPageEmission()
        }
      }
      
    });
  }



  displayPageEmission(){
    this.backendService.get( `/reglages/emissions` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ', resultat );
         this.thePlayList = resultat;
         this.firstElement = this.thePlayList[0];
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      console.log( 'ko :: ', error)
    })
  }


  displayPageCoran( id_audio ){

    this.backendService.get( `/reglages/coran-audio/${id_audio}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ', resultat );
         this.thePlayList = resultat;
         this.firstElement = this.thePlayList[0];
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })


  }


}
