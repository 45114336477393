import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders , HttpEvent, HttpErrorResponse, HttpEventType  } from '@angular/common/http';
import { Observable , throwError } from 'rxjs';

import { map, catchError } from  'rxjs/operators';
import { environment } from 'src/environments/environment';

import { GlobalService } from '../global/global.service'



@Injectable({
  providedIn: 'root'
})
export class BackendService {

  backendUrl = environment.backendUrl

  constructor( 
    private http: HttpClient ,
    private globalService: GlobalService,
  ) {

  }
  

  /**
   * 
   * @param url 
   */
  get( url ) {

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    console.log( `GET ::: ${url}` )

    return this.http
    .get( `${this.backendUrl}${url}` , {headers} )
    .toPromise()
    .then( response => response )
    .catch(error => error )

  }

  /**
   * 
   * @param url 
   * @param param 
   */
  put(url, param_){

    if( param_ == null ){
      return throwError("Null parameter").toPromise()
    }

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    console.log( `PUT ::: ${url}` )

    return this.http
    .put( `${this.backendUrl}${url}`, param_ , {headers} )
    .toPromise()
    .then(response => response)
    .catch(error => error )

  }


  
  /**
   * 
   * @param url 
   * @param param 
   */
  post(url, param_){

    if( param_ == null ){
      return throwError("Null parameter").toPromise()
    }

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    console.log( `POST ::: ${url}` )

    return this.http
    .post( `${this.backendUrl}${url}`, param_ , {headers} )
    .toPromise()
    .then(response => response)
    .catch(error => error )

  }



  /**
   * 
   * @param url 
   */
  delete(url) {

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    console.log( `DELETE ::: ${url}` )

    return this.http
    .delete(`${this.backendUrl}${url}`, {headers} )
    .toPromise()
    .then(response => response)
    .catch(error => error)

  }
  

  /**
   * Upload file 
   * @param data 
   * @param url 
   */
  upload(data, url ) {

    console.log( `UPLOAD ::: ${url}` )

    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    return this.http.post<any>(`${this.backendUrl}${url}`, data ,{
      reportProgress: true,
      observe: 'events',

    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return { status: 'noprogress', message: `Unhandled event: ${event.type}` } ;
      }
    })
    );

  }




  load(){
    
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8')

    console.log( `GET ::: /token/init` )

    return this.http
    .get( `${this.backendUrl}/token/init` , {headers} )
    .toPromise()
    .then( response => {
      
      console.log( 'GET ::: /token/init',  JSON.stringify(response) )
      this.globalService.setVisitor( response )
    } )
    .catch(error => {
      
      
    } )

  }


}
