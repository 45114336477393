

<div class="content-application">

    <app-header></app-header>


    <div class="content-div-app">
        <app-separator-top></app-separator-top>
        
        <div class="contenu-des-pages contenu-home">

            <div class="row espage-in-title">
                <div class="col-md-12 text-h3 text-bold colorRouge">
                    Désolé! Page introuvable
                </div>
            </div>


             <!-- Error Page -->
            <div class="error">
                <div class="container-floud">
                    <div class="col-xs-12 ground-color text-center">
                        <div class="container-error-404">
                            <div class="clip"><div class="shadow"><span class="digit thirdDigit">4</span></div></div>
                            <div class="clip"><div class="shadow"><span class="digit secondDigit">0</span></div></div>
                            <div class="clip"><div class="shadow"><span class="digit firstDigit">4</span></div></div>
                        </div>
                        <h2 class="h1">Désolé! Page introuvable </h2>
                    </div>
                </div>
            </div>
            <!-- Error Page -->


            
        </div>

    </div>

    <app-footer></app-footer>
    
</div>
