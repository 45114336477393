import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuktwa-empire',
  templateUrl: './nuktwa-empire.component.html',
  styleUrls: ['./nuktwa-empire.component.scss']
})
export class NuktwaEmpireComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
