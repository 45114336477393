import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { AudioSService } from '../../services/audio-s/audio-s.service';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-lecteur',
  templateUrl: './lecteur.component.html',
  styleUrls: ['./lecteur.component.scss']
})
export class LecteurComponent implements OnInit {

  audioSubscription: Subscription
  audio_playing_encour : any;

  listeAudio = [];

  emissionEncours = "Variété musicale"
  annimateurEncrous = "Nuktwa Ha Nuktwa"
  photoAnnimateur = 'assets/imgs/icon-nuktwafm.png'
  streamRadio = "https://hstudio.ovh/radionuktwafm.mp3"


  constructor(
    private audioSService: AudioSService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {

    this.displayPage( 'radio' );

  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.observableAudio()
    }, 1000);
  }

  observableAudio(){
    this.audioSubscription = this.audioSService.getPlayerStatus().subscribe(status => {
      
      console.log('Observable lecture audio ', status )

      if ( this.audioSService.getLectureEnCours().type === 'radio' && this.audioSService.getLectureEnCours().identifiant === 'radio_nuktwa' ) {
        
        switch (status) {
          case 'playing':
            this.listeAudio[this.audio_playing_encour.index].playing = true
            this.audio_playing_encour = this.listeAudio[this.audio_playing_encour.index];
            this.loaderService.hide();
            break;
          case 'paused':
            if( this.listeAudio )
              this.listeAudio[this.audio_playing_encour.index].playing = false
            break;
          case 'waiting':
            this.listeAudio[this.audio_playing_encour.index].playing = true
            break;
          case 'ended':
            this.listeAudio[this.audio_playing_encour.index].playing = false
            this.nextPlay()
            break;
          default:
            if( this.listeAudio )
              this.listeAudio[this.audio_playing_encour.index].playing = false
            break;
        }
      }
      
    })
  }


  displayPage( type ){

    if ( type === 'radio' ) {

      // Appel au back 

      this.listeAudio = [ this.initRadio(this.streamRadio) ]
      this.audio_playing_encour = this.listeAudio[0];
    }

  }


  initRadio(stream){
    let entryStreem = {
      index: 0,
      src: stream,
      url_audio: stream,
      title: this.emissionEncours,
      artist: this.annimateurEncrous,
      photo: this.photoAnnimateur,
      type: "radio",
      playing: false
    }
    return entryStreem
  }


  async playAudio(){


    if (!this.audio_playing_encour) {
      this.audio_playing_encour = this.listeAudio[0];
    }

    this.audioSService.setPlaylist( this.listeAudio );

    if( this.audio_playing_encour && this.audio_playing_encour.url_audio ) {

      if( this.audio_playing_encour.playing ) {
        this.audioSService.pauseAudio()
      } else {

        this.loaderService.show()
        
        if( this.audioSService.isPlaying() ) {
          this.audioSService.closePlayer()
        }
        this.audioSService.setAudio(this.audio_playing_encour.url_audio)
        // this.audioSService.setTypePlaying( this.audio_playing_encour.type )
        this.audioSService.setLectureEnCours( 'radio', 'radio_nuktwa', 0)
      }
    }

  }

  nextPlay(){
    if( this.audio_playing_encour &&  ((this.audio_playing_encour.index + 1) < this.listeAudio.length) ) {

      this.audio_playing_encour = this.listeAudio[this.audio_playing_encour.index + 1];
      this.playAudio()
    } else {
      this.audio_playing_encour = this.listeAudio[0];
    }
  }


}
