import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../../services/global/global.service';
import { BackendService } from '../../services/backend/backend.service';

@Component({
  selector: 'app-content-home',
  templateUrl: './content-home.component.html',
  styleUrls: ['./content-home.component.scss']
})
export class ContentHomeComponent implements OnInit {

  arrayFlashInfo = [];
  listeCoran = [];
  isCoran = true;
  retourMessage = { ok: null, ko: null };

  constructor(
    private globalService: GlobalService,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    this.arrayFlashInfo = this.globalService.initFlashInfo();

    // Ramadan :
    this.getCoranList();
  }


  getCoranList() {

    this.backendService.get( `/reglages/coran` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ', resultat );
         this.listeCoran = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }


}
