import { Injectable } from '@angular/core';
import { HttpClient , HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BackendDirectService {

  autoRadioRunin = false

  private msgErreur = JSON.parse('{}');
  private ussoma_url = 'https://hachamco.fr/ussoma-web/api_ussoma/'
  private headers = new HttpHeaders()
  .set('Access-Control-Allow-Origin', "*")
  .set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE')

  public mesChoixDeVoix = []


  constructor( private http: HttpClient ) { }


  public getData(param, type): Promise<any> {
		
    let url = this.ussoma_url
    let body = JSON.stringify(param);
    
    return this.http
    .post(url, body) 
    .toPromise()
    .then(response => response)
    .catch(error => {
        return this.msgErreur.json() ;
    });

  }


  setMesChoixDeVoix(choix) {
    this.mesChoixDeVoix = choix
  }

  getMesChoixDeVoix(){
    return this.mesChoixDeVoix;
  }


  isRadioRunning(url){
    return this.http
    .get(url)
    .toPromise()
    .then(response => response)
    .catch(error => error)
  }


  getErrorCode(){
    return [
      0, 401, 403, 404, 422, 500, 501, 503, 301, 302 
    ];
  }

  
  ifAutoRadio(){
    return this.autoRadioRunin
  }

  setAutoRadio(radio){
    this.autoRadioRunin = radio
  }



  radioAutoDj(){
/*
   let tracks = {
      "tracks": [
        {
          "id_musique": "2688",
          "title": "Mbiyo ft Rayad",
          "nb_ecoute": "50",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "190",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/1-mbiyo-feat-rayad.mp3",
          "titre_search": "Mbiyo ft Rayad RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2689",
          "title": "Hamdrengue",
          "nb_ecoute": "33",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "210",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/2-hamdrengue.mp3",
          "titre_search": "Hamdrengue RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2690",
          "title": "Ntsaphuho",
          "nb_ecoute": "25",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "160",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/3-ntsapvuho.mp3",
          "titre_search": "Ntsaphuho RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2691",
          "title": "Ngue Numawo ft Lhadi Bakari",
          "nb_ecoute": "31",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "158",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/4-ngue-numawo-feat-lhadi-bakari.mp3",
          "titre_search": "Ngue Numawo ft Lhadi Bakari RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2692",
          "title": "Mbahuma",
          "nb_ecoute": "25",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "209",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/5-mbahuma.mp3",
          "titre_search": "Mbahuma RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2693",
          "title": "Uwade",
          "nb_ecoute": "18",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "172",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/6-uwade.mp3",
          "titre_search": "Uwade RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2694",
          "title": "Maman Hafu",
          "nb_ecoute": "23",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "175",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/7-maman-hafu.mp3",
          "titre_search": "Maman Hafu RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2695",
          "title": "Dakika bo Daika",
          "nb_ecoute": "17",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "159",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/8-dakika-bo-daika.mp3",
          "titre_search": "Dakika bo Daika RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2696",
          "title": "Amina ft Dania Kassim",
          "nb_ecoute": "18",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "179",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/9-amina-feat-dania-kassim.mp3",
          "titre_search": "Amina ft Dania Kassim RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        },
        {
          "id_musique": "2697",
          "title": "Massiwa ft Asam",
          "nb_ecoute": "20",
          "image": "http://admindjymbo.hachamco.fr/albums/album_31._SS500_jpg",
          "id_chanteur": "316",
          "artist": "RDJB",
          "id_categ": "3",
          "categorie": "RAP - R&B - POP",
          "id_album": "31",
          "duration": "226",
          "src": "http://chansonscomorienne.hachamco.fr/RAP/RDJB/SANDUK/91-massiwa-feat-asam.mp3",
          "titre_search": "Massiwa ft Asam RDJB",
          "playing": false,
          "progress": 0,
          "album": ""
        }
      ],
      "coverList": "http://admindjymbo.hachamco.fr/chanteur/chanteur_316.jpg",
      "titreCover": "RDJB",
      "detailCover": " (Homme)",
      "genreCover": "RAP - R&B - POP",
      "userPlaylist": 1
   };
   return tracks.tracks;
*/

   let array_list = [
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/1.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Fatiha",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/1.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الفَاتِحَة",
         "total_ayat":"6",
         "txt_fr":"Al-Fatiha",
         "txt_franc":"",
         "num_sourate":"1",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/2.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Baqara",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/2.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"البَقَرَة",
         "total_ayat":"286",
         "txt_fr":"Al-Baqara",
         "txt_franc":"",
         "num_sourate":"2",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/3.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-E-Imran",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/3.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"آل عِمرَان",
         "total_ayat":"200",
         "txt_fr":"Al-E-Imran",
         "txt_franc":"",
         "num_sourate":"3",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/4.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"An-Nisa",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/4.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النِّسَاء",
         "total_ayat":"176",
         "txt_fr":"An-Nisa",
         "txt_franc":"",
         "num_sourate":"4",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/5.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Maeda",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/5.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المَائدة",
         "total_ayat":"120",
         "txt_fr":"Al-Maeda",
         "txt_franc":"",
         "num_sourate":"5",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/6.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Anaam",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/6.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الاٴنعَام",
         "total_ayat":"165",
         "txt_fr":"Al-Anaam",
         "txt_franc":"",
         "num_sourate":"6",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/7.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Araf",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/7.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الاٴعرَاف",
         "total_ayat":"206",
         "txt_fr":"Al-Araf",
         "txt_franc":"",
         "num_sourate":"7",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/8.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Anfal",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/8.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الاٴنفَال",
         "total_ayat":"75",
         "txt_fr":"Al-Anfal",
         "txt_franc":"",
         "num_sourate":"8",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/9.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Tawba",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/9.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"التّوبَة",
         "total_ayat":"129",
         "txt_fr":"Al-Tawba",
         "txt_franc":"",
         "num_sourate":"9",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/10.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Yunus",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/10.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"یُونس",
         "total_ayat":"109",
         "txt_fr":"Yunus",
         "txt_franc":"",
         "num_sourate":"10",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/11.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Hud",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/11.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"هُود",
         "total_ayat":"123",
         "txt_fr":"Hud",
         "txt_franc":"",
         "num_sourate":"11",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/12.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Yusuf",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/12.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"یُوسُف",
         "total_ayat":"111",
         "txt_fr":"Yusuf",
         "txt_franc":"",
         "num_sourate":"12",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/13.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Rad",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/13.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الرّعد",
         "total_ayat":"43",
         "txt_fr":"Al-Rad",
         "txt_franc":"",
         "num_sourate":"13",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/14.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ibrahim",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/14.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"إبراهیم",
         "total_ayat":"52",
         "txt_fr":"Ibrahim",
         "txt_franc":"",
         "num_sourate":"14",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/15.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Hijr",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/15.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الحِجر",
         "total_ayat":"99",
         "txt_fr":"Al-Hijr",
         "txt_franc":"",
         "num_sourate":"15",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/16.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"An-Nahl",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/16.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النّحل",
         "total_ayat":"128",
         "txt_fr":"An-Nahl",
         "txt_franc":"",
         "num_sourate":"16",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/17.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Isra",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/17.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"بنیٓ اسرآئیل / الإسرَاء",
         "total_ayat":"111",
         "txt_fr":"Al-Isra",
         "txt_franc":"",
         "num_sourate":"17",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/18.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Kahf",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/18.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الکهف",
         "total_ayat":"110",
         "txt_fr":"Al-Kahf",
         "txt_franc":"",
         "num_sourate":"18",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/19.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Maryam",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/19.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"مَریَم",
         "total_ayat":"98",
         "txt_fr":"Maryam",
         "txt_franc":"",
         "num_sourate":"19",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/20.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ta-Ha",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/20.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"طٰه",
         "total_ayat":"135",
         "txt_fr":"Ta-Ha",
         "txt_franc":"",
         "num_sourate":"20",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/21.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Anbiya",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/21.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الاٴنبیَاء",
         "total_ayat":"112",
         "txt_fr":"Al-Anbiya",
         "txt_franc":"",
         "num_sourate":"21",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/22.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Hajj",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/22.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الحَجّ",
         "total_ayat":"78",
         "txt_fr":"Al-Hajj",
         "txt_franc":"",
         "num_sourate":"22",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/23.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Mumenoon",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/23.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المؤمنون",
         "total_ayat":"118",
         "txt_fr":"Al-Mumenoon",
         "txt_franc":"",
         "num_sourate":"23",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/24.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Noor",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/24.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النُّور",
         "total_ayat":"64",
         "txt_fr":"Al-Noor",
         "txt_franc":"",
         "num_sourate":"24",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/25.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Furqan",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/25.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الفُرقان",
         "total_ayat":"77",
         "txt_fr":"Al-Furqan",
         "txt_franc":"",
         "num_sourate":"25",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/26.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Shuara",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/26.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الشُّعَرَاء",
         "total_ayat":"227",
         "txt_fr":"Al-Shuara",
         "txt_franc":"",
         "num_sourate":"26",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/27.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Naml",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/27.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النَّمل",
         "total_ayat":"93",
         "txt_fr":"Al-Naml",
         "txt_franc":"",
         "num_sourate":"27",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/28.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Qasas",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/28.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"القَصَص",
         "total_ayat":"88",
         "txt_fr":"Al-Qasas",
         "txt_franc":"",
         "num_sourate":"28",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/29.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Ankaboot",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/29.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"العَنکبوت",
         "total_ayat":"69",
         "txt_fr":"Al-Ankaboot",
         "txt_franc":"",
         "num_sourate":"29",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/30.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Room",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/30.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الرُّوم",
         "total_ayat":"60",
         "txt_fr":"Al-Room",
         "txt_franc":"",
         "num_sourate":"30",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/31.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Luqman",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/31.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"لقمَان",
         "total_ayat":"34",
         "txt_fr":"Luqman",
         "txt_franc":"",
         "num_sourate":"31",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/32.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"As-Sajda",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/32.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"السَّجدَة",
         "total_ayat":"30",
         "txt_fr":"As-Sajda",
         "txt_franc":"",
         "num_sourate":"32",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/33.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Ahzab",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/33.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الاٴحزَاب",
         "total_ayat":"73",
         "txt_fr":"Al-Ahzab",
         "txt_franc":"",
         "num_sourate":"33",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/34.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Saba",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/34.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"سَبَإ",
         "total_ayat":"54",
         "txt_fr":"Saba",
         "txt_franc":"",
         "num_sourate":"34",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/35.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Fatir",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/35.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"فَاطِر",
         "total_ayat":"45",
         "txt_fr":"Fatir",
         "txt_franc":"",
         "num_sourate":"35",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/36.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ya-Seen",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/36.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"یسٓ",
         "total_ayat":"83",
         "txt_fr":"Ya-Seen",
         "txt_franc":"",
         "num_sourate":"36",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/37.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"As-Saaffat",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/37.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الصَّافات",
         "total_ayat":"182",
         "txt_fr":"As-Saaffat",
         "txt_franc":"",
         "num_sourate":"37",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/38.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Sad",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/38.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"صٓ",
         "total_ayat":"88",
         "txt_fr":"Sad",
         "txt_franc":"",
         "num_sourate":"38",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/39.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Az-Zumar",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/39.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الزُّمَر",
         "total_ayat":"75",
         "txt_fr":"Az-Zumar",
         "txt_franc":"",
         "num_sourate":"39",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/40.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ghafir",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/40.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المؤمن / غَافر",
         "total_ayat":"85",
         "txt_fr":"Ghafir",
         "txt_franc":"",
         "num_sourate":"40",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/41.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Fussilat",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/41.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"حٰمٓ السجدة / فُصّلَت",
         "total_ayat":"54",
         "txt_fr":"Fussilat",
         "txt_franc":"",
         "num_sourate":"41",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/42.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ash-Shura",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/42.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الشّوریٰ",
         "total_ayat":"53",
         "txt_fr":"Ash-Shura",
         "txt_franc":"",
         "num_sourate":"42",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/43.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Az-Zukhruf",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/43.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الزّخرُف",
         "total_ayat":"89",
         "txt_fr":"Az-Zukhruf",
         "txt_franc":"",
         "num_sourate":"43",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/44.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ad-Dukhan",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/44.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الدّخان",
         "total_ayat":"59",
         "txt_fr":"Ad-Dukhan",
         "txt_franc":"",
         "num_sourate":"44",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/45.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Jathiya",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/45.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الجَاثیَة",
         "total_ayat":"37",
         "txt_fr":"Al-Jathiya",
         "txt_franc":"",
         "num_sourate":"45",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/46.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Ahqaf",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/46.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الاٴحقاف",
         "total_ayat":"35",
         "txt_fr":"Al-Ahqaf",
         "txt_franc":"",
         "num_sourate":"46",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/47.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Muhammad",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/47.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"محَمَّد",
         "total_ayat":"38",
         "txt_fr":"Muhammad",
         "txt_franc":"",
         "num_sourate":"47",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/48.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Fath",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/48.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الفَتْح",
         "total_ayat":"29",
         "txt_fr":"Al-Fath",
         "txt_franc":"",
         "num_sourate":"48",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/49.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Hujraat",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/49.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الحُجرَات",
         "total_ayat":"18",
         "txt_fr":"Al-Hujraat",
         "txt_franc":"",
         "num_sourate":"49",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/50.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Qaf",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/50.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"قٓ",
         "total_ayat":"45",
         "txt_fr":"Qaf",
         "txt_franc":"",
         "num_sourate":"50",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/51.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Adh-Dhariyat",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/51.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الذّاریَات",
         "total_ayat":"60",
         "txt_fr":"Adh-Dhariyat",
         "txt_franc":"",
         "num_sourate":"51",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/52.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Tur",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/52.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الطُّور",
         "total_ayat":"49",
         "txt_fr":"At-Tur",
         "txt_franc":"",
         "num_sourate":"52",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/53.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"An-Najm",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/53.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النّجْم",
         "total_ayat":"62",
         "txt_fr":"An-Najm",
         "txt_franc":"",
         "num_sourate":"53",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/54.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Qamar",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/54.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"القَمَر",
         "total_ayat":"55",
         "txt_fr":"Al-Qamar",
         "txt_franc":"",
         "num_sourate":"54",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/55.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ar-Rahman",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/55.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الرَّحمٰن",
         "total_ayat":"78",
         "txt_fr":"Ar-Rahman",
         "txt_franc":"",
         "num_sourate":"55",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/56.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Waqia",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/56.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الواقِعَة",
         "total_ayat":"96",
         "txt_fr":"Al-Waqia",
         "txt_franc":"",
         "num_sourate":"56",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/57.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Hadid",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/57.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الحَدید",
         "total_ayat":"29",
         "txt_fr":"Al-Hadid",
         "txt_franc":"",
         "num_sourate":"57",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/58.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Mujadila",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/58.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المجَادلة",
         "total_ayat":"22",
         "txt_fr":"Al-Mujadila",
         "txt_franc":"",
         "num_sourate":"58",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/59.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Hashr",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/59.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الحَشر",
         "total_ayat":"24",
         "txt_fr":"Al-Hashr",
         "txt_franc":"",
         "num_sourate":"59",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/60.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Mumtahina",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/60.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المُمتَحنَة",
         "total_ayat":"13",
         "txt_fr":"Al-Mumtahina",
         "txt_franc":"",
         "num_sourate":"60",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/61.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"As-Saff",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/61.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الصَّف",
         "total_ayat":"14",
         "txt_fr":"As-Saff",
         "txt_franc":"",
         "num_sourate":"61",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/62.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Jumua",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/62.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الجُمُعَة",
         "total_ayat":"11",
         "txt_fr":"Al-Jumua",
         "txt_franc":"",
         "num_sourate":"62",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/63.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Munafiqoon",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/63.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المنَافِقون",
         "total_ayat":"11",
         "txt_fr":"Al-Munafiqoon",
         "txt_franc":"",
         "num_sourate":"63",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/64.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Taghabun",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/64.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"التّغَابُن",
         "total_ayat":"18",
         "txt_fr":"At-Taghabun",
         "txt_franc":"",
         "num_sourate":"64",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/65.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Talaq",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/65.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الطّلاَق",
         "total_ayat":"12",
         "txt_fr":"At-Talaq",
         "txt_franc":"",
         "num_sourate":"65",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/66.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Tahrim",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/66.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"التّحْریم",
         "total_ayat":"12",
         "txt_fr":"At-Tahrim",
         "txt_franc":"",
         "num_sourate":"66",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/67.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Mulk",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/67.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المُلک",
         "total_ayat":"30",
         "txt_fr":"Al-Mulk",
         "txt_franc":"",
         "num_sourate":"67",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/68.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Qalam",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/68.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"القَلَم",
         "total_ayat":"52",
         "txt_fr":"Al-Qalam",
         "txt_franc":"",
         "num_sourate":"68",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/69.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Haaqqa",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/69.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الحَاقَّة",
         "total_ayat":"52",
         "txt_fr":"Al-Haaqqa",
         "txt_franc":"",
         "num_sourate":"69",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/70.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Maarij",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/70.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المعَارج",
         "total_ayat":"44",
         "txt_fr":"Al-Maarij",
         "txt_franc":"",
         "num_sourate":"70",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/71.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Nooh",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/71.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"نُوح",
         "total_ayat":"28",
         "txt_fr":"Nooh",
         "txt_franc":"",
         "num_sourate":"71",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/72.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Jinn",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/72.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الجنّ",
         "total_ayat":"28",
         "txt_fr":"Al-Jinn",
         "txt_franc":"",
         "num_sourate":"72",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/73.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Muzzammil",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/73.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المُزمّل",
         "total_ayat":"20",
         "txt_fr":"Al-Muzzammil",
         "txt_franc":"",
         "num_sourate":"73",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/74.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Muddaththir",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/74.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المدَّثِّر",
         "total_ayat":"56",
         "txt_fr":"Al-Muddaththir",
         "txt_franc":"",
         "num_sourate":"74",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/75.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Qiyama",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/75.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"القِیَامَة",
         "total_ayat":"40",
         "txt_fr":"Al-Qiyama",
         "txt_franc":"",
         "num_sourate":"75",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/76.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Insan",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/76.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"ٱلدَّهۡر / الإنسَان",
         "total_ayat":"31",
         "txt_fr":"Al-Insan",
         "txt_franc":"",
         "num_sourate":"76",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/77.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Mursalat",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/77.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المُرسَلات",
         "total_ayat":"50",
         "txt_fr":"Al-Mursalat",
         "txt_franc":"",
         "num_sourate":"77",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/78.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"An-Naba",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/78.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النّبَإِ",
         "total_ayat":"40",
         "txt_fr":"An-Naba",
         "txt_franc":"",
         "num_sourate":"78",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/79.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"An-Naziat",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/79.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النَّازعَات",
         "total_ayat":"46",
         "txt_fr":"An-Naziat",
         "txt_franc":"",
         "num_sourate":"79",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/80.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Abasa",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/80.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"عَبَسَ",
         "total_ayat":"42",
         "txt_fr":"Abasa",
         "txt_franc":"",
         "num_sourate":"80",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/81.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Takwir",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/81.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"التّکویر",
         "total_ayat":"29",
         "txt_fr":"At-Takwir",
         "txt_franc":"",
         "num_sourate":"81",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/82.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Infitar",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/82.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الانفِطار",
         "total_ayat":"19",
         "txt_fr":"Al-Infitar",
         "txt_franc":"",
         "num_sourate":"82",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/83.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Mutaffifin",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/83.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المطفّفِین",
         "total_ayat":"36",
         "txt_fr":"Al-Mutaffifin",
         "txt_franc":"",
         "num_sourate":"83",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/84.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Inshiqaq",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/84.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الانشقاق",
         "total_ayat":"25",
         "txt_fr":"Al-Inshiqaq",
         "txt_franc":"",
         "num_sourate":"84",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/85.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Burooj",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/85.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"البُرُوج",
         "total_ayat":"22",
         "txt_fr":"Al-Burooj",
         "txt_franc":"",
         "num_sourate":"85",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/86.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Tariq",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/86.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الطّارق",
         "total_ayat":"17",
         "txt_fr":"At-Tariq",
         "txt_franc":"",
         "num_sourate":"86",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/87.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Ala",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/87.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الاٴعلی",
         "total_ayat":"19",
         "txt_fr":"Al-Ala",
         "txt_franc":"",
         "num_sourate":"87",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/88.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Ghashiya",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/88.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الغَاشِیَة",
         "total_ayat":"26",
         "txt_fr":"Al-Ghashiya",
         "txt_franc":"",
         "num_sourate":"88",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/89.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Fajr",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/89.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الفَجر",
         "total_ayat":"30",
         "txt_fr":"Al-Fajr",
         "txt_franc":"",
         "num_sourate":"89",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/90.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Balad",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/90.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"البَلَد",
         "total_ayat":"20",
         "txt_fr":"Al-Balad",
         "txt_franc":"",
         "num_sourate":"90",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/91.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ash-Shams",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/91.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الشّمس",
         "total_ayat":"15",
         "txt_fr":"Ash-Shams",
         "txt_franc":"",
         "num_sourate":"91",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/92.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Lail",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/92.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"اللیْل",
         "total_ayat":"21",
         "txt_fr":"Al-Lail",
         "txt_franc":"",
         "num_sourate":"92",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/93.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ad-Dhuha",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/93.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الِضُّحىٰ",
         "total_ayat":"11",
         "txt_fr":"Ad-Dhuha",
         "txt_franc":"",
         "num_sourate":"93",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/94.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Ash-Sharh",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/94.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الشَّرح",
         "total_ayat":"8",
         "txt_fr":"Ash-Sharh",
         "txt_franc":"",
         "num_sourate":"94",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/95.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Tin",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/95.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"التِّین",
         "total_ayat":"8",
         "txt_fr":"At-Tin",
         "txt_franc":"",
         "num_sourate":"95",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/96.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Alaq",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/96.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"العَلق",
         "total_ayat":"19",
         "txt_fr":"Al-Alaq",
         "txt_franc":"",
         "num_sourate":"96",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/97.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Qadr",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/97.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"القَدر",
         "total_ayat":"5",
         "txt_fr":"Al-Qadr",
         "txt_franc":"",
         "num_sourate":"97",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/98.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Bayyina",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/98.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"البَیّنَة",
         "total_ayat":"8",
         "txt_fr":"Al-Bayyina",
         "txt_franc":"",
         "num_sourate":"98",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/99.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Zalzala",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/99.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الزّلزَلة",
         "total_ayat":"8",
         "txt_fr":"Al-Zalzala",
         "txt_franc":"",
         "num_sourate":"99",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/100.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Adiyat",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/100.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"العَادیَات",
         "total_ayat":"11",
         "txt_fr":"Al-Adiyat",
         "txt_franc":"",
         "num_sourate":"100",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/101.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Qaria",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/101.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"القَارعَة",
         "total_ayat":"11",
         "txt_fr":"Al-Qaria",
         "txt_franc":"",
         "num_sourate":"101",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/102.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"At-Takathur",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/102.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"التّکاثُر",
         "total_ayat":"8",
         "txt_fr":"At-Takathur",
         "txt_franc":"",
         "num_sourate":"102",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/103.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Asr",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/103.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"العَصر",
         "total_ayat":"3",
         "txt_fr":"Al-Asr",
         "txt_franc":"",
         "num_sourate":"103",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/104.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Humaza",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/104.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الهُمَزة",
         "total_ayat":"9",
         "txt_fr":"Al-Humaza",
         "txt_franc":"",
         "num_sourate":"104",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/105.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Fil",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/105.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الفِیل",
         "total_ayat":"5",
         "txt_fr":"Al-Fil",
         "txt_franc":"",
         "num_sourate":"105",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/106.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Quraish",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/106.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"قُرَیش",
         "total_ayat":"4",
         "txt_fr":"Quraish",
         "txt_franc":"",
         "num_sourate":"106",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/107.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Maun",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/107.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"المَاعون",
         "total_ayat":"7",
         "txt_fr":"Al-Maun",
         "txt_franc":"",
         "num_sourate":"107",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/108.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Kauther",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/108.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الکَوثَر",
         "total_ayat":"3",
         "txt_fr":"Al-Kauther",
         "txt_franc":"",
         "num_sourate":"108",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/109.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Kafiroon",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/109.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الکافِرون",
         "total_ayat":"6",
         "txt_fr":"Al-Kafiroon",
         "txt_franc":"",
         "num_sourate":"109",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/110.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"An-Nasr",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/110.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النّصر",
         "total_ayat":"3",
         "txt_fr":"An-Nasr",
         "txt_franc":"",
         "num_sourate":"110",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/111.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Masadd",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/111.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"لهب / المَسَد",
         "total_ayat":"5",
         "txt_fr":"Al-Masadd",
         "txt_franc":"",
         "num_sourate":"111",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/112.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Ikhlas",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/112.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الإخلاص",
         "total_ayat":"4",
         "txt_fr":"Al-Ikhlas",
         "txt_franc":"",
         "num_sourate":"112",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/113.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"Al-Falaq",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/113.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"الفَلَق",
         "total_ayat":"5",
         "txt_fr":"Al-Falaq",
         "txt_franc":"",
         "num_sourate":"113",
         "playing":false,
         "progress":0,
         "album":""
      },
      {
         "id_audio":"7",
         "txt_nom":"عبد الرحمن السديس",
         "lien_audios":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/114.mp3",
         "photo":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "image":"https://hachamco.fr/admin_ussoma/recitateurs/recit7.jpg",
         "nom_fr":"Abderrahman Al Soudais",
         "artist":"Abderrahman Al Soudais",
         "title":"An-Nas",
         "src":"https://hachamco.fr/admin_ussoma/recitateurs/soudais/114.mp3",
         "is_download":0,
         "duration":0,
         "txt_coran":"النَّاس",
         "total_ayat":"6",
         "txt_fr":"An-Nas",
         "txt_franc":"",
         "num_sourate":"114",
         "playing":false,
         "progress":0,
         "album":""
      }
   ]
   return array_list;

  }



}
