<div class="footerfix-app fixed-bottom margpad-botton-fixed " *ngIf="playlist_playing_encour && canShowBare" > <!--    -->


    <div class="row slider-duration" *ngIf="playlist_playing_encour.type && playlist_playing_encour.type !== 'radio' " >
        <div class="col-1 duration-time d-none d-md-block d-xl-block ">{{ lecteurState.minEncours }}</div>
        <div class="col-md-10 col-12 cursor" > <!-- (click)="progressChange()"  -->
            <ngb-progressbar #progress_bar id="progress_bar" type="danger" [max]="lecteurState.durationTime" [value]="lecteurState?.currentTime" [striped]="true" [animated]="true"><i>{{ lecteurState.minEncours }}</i></ngb-progressbar>
        </div>
        <div class="col-1 duration-time d-none d-md-block d-xl-block ">{{ lecteurState.minEnd }}</div>
    </div>

    <div class="row">
    
        <div class="col-xl-1 col-md-1 d-none d-md-block d-xl-block d-lg-block partie-logo">
            <img src="assets/imgs/icon-nuktwafm.png" class="w-100 h-100" />
        </div>

        <div class="col-3 col-sm-3 d-sm-block d-md-none d-xl-none d-lg-none partie-logo colorRouge cursor " (click)="playInBottom()" >
            <!--<span class="material-icons"> 
                {{ ( playlist_playing_encour.playing ) ? 'stop' : 'play_circle_filled'   }}
            </span> -->

            <img *ngIf="!playlist_playing_encour.playing" src="assets/imgs/play_rouge.png" class="img-icon-player" />
            <img *ngIf="playlist_playing_encour.playing" src="assets/imgs/stop_rouge.png" class="img-icon-player" />

        </div>

        <div class="col-xl-5 col-md-5 col-sm-7 col-7 partie-info">
            <span class="text-12 text-bold colorBlanc"> {{playlist_playing_encour.title}} </span> <br />
            <span class="text-12 colorBlanc" *ngIf="playlist_playing_encour.txt_coran"  > {{playlist_playing_encour.txt_coran}} </span> <br />
            <span class="text-12 colorBlanc" > {{playlist_playing_encour.artist}} </span> <br />

            <div class="row text-12 colorBlanc" *ngIf="playlist_playing_encour.type && playlist_playing_encour.type !== 'radio' ">
                <div class="col">
                    {{ lecteurState.minEncours }}
                </div>
                <div class="col displayRight text-bold">
                    {{ lecteurState.minEnd }}
                </div>
            </div>
        </div>

        <div class="col-xl-5 col-md-5 d-none d-md-block d-xl-block partie-lecteur-audio">
            
            <div class="row colorBlanc ">
                <div class="col" *ngIf="playlist_playing_encour.index !== 0" >
                    <span class="material-icons iconNextPrev cursor" (click)="prevPlay()" > skip_previous </span>
                </div>
                <div class="col cursor" (click)="playInBottom()" >
                    <!--<span class="material-icons iconPlay cursor" (click)="playInBottom()">
                        {{ ( playlist_playing_encour.playing ) ? 'stop' : 'play_circle_filled' }}
                    </span>-->

                    <img *ngIf="!playlist_playing_encour.playing" src="assets/imgs/play_blanc.png" class="img-icon-player-blanc" />
                    <img *ngIf="playlist_playing_encour.playing" src="assets/imgs/stop_blanc.png" class="img-icon-player-blanc" />

                </div>
                <div class="col" *ngIf=" !isLastPlaying() " >
                    <span class="material-icons iconNextPrev cursor" (click)="nextPlay()" > skip_next </span>
                </div>
            </div>
        </div>

        <!--<div 
            class="col-xl-1 col-md-1 col-sm-2 col-2 partie-close colorBlanc cursor 
            d-none d-md-block d-lg-block d-xl-block "  d-md-none d-lg-none d-xl-none
            (click)="closePlayer()" >
            X
        </div>-->

        <div 
            class="col-xl-1 col-md-1 col-sm-2 col-2 partie-close-2 colorBlanc cursor 
            " 
            (click)="closePlayer()" >
            <img src="assets/imgs/close.png" />
        </div>

    </div>

</div>