

<div class="content-application">

    <app-header></app-header>

    


    <div class="content-div-app">
        <app-separator-top></app-separator-top>
        
        <div class="contenu-des-pages">
            
            <div class="contenu-home">
                
                <div *ngIf="firstElement" class="partie-presentation">
                    <div class="row">
                        <div class="col-md-4">
                            <img alt="" class="max-img-height background-image"  
                                [src]="firstElement.image ? firstElement.image : firstElement.photo"
                            />
                        </div>
                        <div class="col-md-8 d-none d-md-block d-lg-block d-xl-block container-vertical">
                            
                            <div class="vertical-center text-center">
                                <span class="text-h3 text-bold colorRouge"> {{firstElement.artist}} </span> <br />
                                <span class="text-h4" *ngIf="firstElement.txt_nom"> {{firstElement.txt_nom}} </span> <br />

                                <span class="text-14 padding-top-10">
                                    {{typeRead === 'coran' ? "Retrouvez l'intégralité du Saint Coran en podcasts !" : "Retrouvez l'intégralité des émissions en podcasts !"}}
                                </span>
                            </div>
                        </div>

                        <div class="col-md-8 d-md-none d-lg-none d-xl-none ">
                            
                            <div class="padding-top-10">
                                <span class="text-h3 text-bold colorRouge"> {{firstElement.artist}} </span> <br />
                                <span class="text-h4" *ngIf="firstElement.txt_nom"> {{firstElement.txt_nom}} </span> <br />

                                <span class="text-14 padding-top-10">
                                    {{typeRead === 'coran' ? "Retrouvez l'intégralité du Saint Coran en podcasts !" : "Retrouvez l'intégralité des émissions en podcasts !"}}
                                </span>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="partie-playlist padding-top-10">

                    <app-audio-player
                    [thePlayList]="thePlayList"
                    [imageShow] = "imageShow"
                    [typeRead] = "typeRead" 
                    [identifiantRead] = "identifiantRead" 
                    [playlist_playing_encour] = "playlist_playing_encour"
                    > </app-audio-player>

                </div>

                

                <!-- Partie lecteur bottom -->

                
                <!-- Partie lecteur bottom -->


            </div>
        </div>

        
    </div>



    <app-footer></app-footer>
    
</div>
