

<div class="content-application">

    <app-header></app-header>

    


    <div class="content-div-app">
        <app-separator-top></app-separator-top>
        
        <div class="contenu-des-pages contenu-home">

            <div class="row espage-in-title">
                <div class="col-md-12 text-h3 text-bold colorRouge">
                    Vidéo Club 
                </div>

            </div>


            <div *ngIf="videosArray.length > 0" class="row display-video ">
                <div class="col margin-bottom-20" *ngFor="let vty of videosArray" >
                    <div class="row">
                        <div class="col-12">
                            <app-youtube-video [videoId]="vty.id.videoId" ></app-youtube-video>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 titre-video colorRouge text-bold text-center text-14 text-wrap">
                            {{ vty.snippet.title }}
                        </div>
                    </div>
                </div>
            </div>

            
        </div>

        
    </div>


    <app-footer></app-footer>
    
</div>
