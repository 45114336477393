import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../../services/global/global.service';
import { BackendDirectService } from '../../services/backend-direct/backend.service';
import { EventService } from '../../services/events/events.service';
import { BackendService } from '../../services/backend/backend.service';
import { AudioSService } from '../../services/audio-s/audio-s.service';
import { LoaderService } from '../../services/loader/loader.service';



@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrls: ['./programme.component.scss']
})
export class ProgrammeComponent implements OnInit {

  retourMessage = { ok: null, ko: null };
  lesProgramme;

  constructor(
    private globalService: GlobalService,
    private audioSService: AudioSService,
    private eventService: EventService,
    private backendService: BackendService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {

    this.getProgrammes();
  }


  getProgrammes() {
    this.backendService.get( `/reglages/programme` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat lesProgramme', resultat );
         this.lesProgramme = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      console.log( 'ko :: ', error)
    })
  }

}
