
<app-footer-lecteur> </app-footer-lecteur>

<div class="footer-app">

    <div class="row div-urls-footer">
        <div class="col-md-3 footer-left group-div">
          <span class="titreDiv">Nuktwa Fm</span> <br /> <br />
          <p>
              Nuktwa-FM est une radio comorienne d'actualités, diffusée mondialement en comorien et en français.
          </p>
        </div>
        <div class="col-md-3 footer-center group-div">
          <span class="titreDiv"> Liens rapides </span> <br /> <br />
            <div class="row">
              <div class="col-12">
                <div class="row inter-padding"><div class="col-12"><a class="cursor" (click)="openPage('/nos-applications')" > <i class="fa fa-mobile" aria-hidden="true" ></i>Applications </a></div></div>
                <div class="row inter-padding"><div class="col-12"><a class="cursor" (click)="openPage('/play-audio/nos-podcast/audios')" > <i class="fa fa-music" aria-hidden="true" ></i> Podcast</a></div></div>
                <!-- <div class="row inter-padding"><div class="col-12"><a class="cursor" (click)="openPage('/notre-equipe')" > <i class="fa fa-users" aria-hidden="true" ></i> Équipe</a></div></div> -->
                <div class="row inter-padding"><div class="col-12"><a class="cursor" (click)="openPage('/nos-videos')" > <i class="fa fa-video-camera" aria-hidden="true" ></i> Vidéos</a></div></div>
              </div>
            </div>
        </div>
        <div class="col-md-3 footer-center group-div">
          <span class="titreDiv">&nbsp;</span> <br /> <br />
            <div class="row">
              <div class="col-12">
                  <div class="row inter-padding"><div class="col-12"><a class="cursor" (click)="openPage('/nuktwa-production')" > <i class="fa fa-music" aria-hidden="true" ></i> Nuktwa Production</a></div></div>
                  <div class="row inter-padding"><div class="col-12"><a class="cursor" (click)="openPage('/nous-contacter')" > <i class="fa fa-envelope" aria-hidden="true" ></i> Contact</a></div></div>
                  <div class="row inter-padding"><div class="col-12"><a class="cursor" (click)="openPage('/programme')" > <i class="fa fa-cogs" aria-hidden="true" ></i>Programmes </a></div></div>
              </div>
            </div>
        </div>
        <!--<div class="col-md-3 footer-center group-div">
          <span class="titreDiv">&nbsp;</span> <br /> <br />
            <span class="titreDiv">L'Empire Nuktwa</span>
            <div class="row">
              <div class="col-12">
                <div class="row inter-padding"><div class="col-12"><a class="" href="#" > <i class="fa fa-cogs" aria-hidden="true" ></i> Nuktwa Production</a></div></div>
                <div class="row inter-padding"><div class="col-12"><a class="" href="#" > <i class="fa fa-microphone" aria-hidden="true" ></i> Nuktwa Fm</a></div></div>
                <div class="row inter-padding"><div class="col-12"><a class="" href="#" > <i class="fa fa-globe" aria-hidden="true" ></i> Nuktwa Connect</a></div></div>
                <div class="row inter-padding"><div class="col-12"><a class="" href="#" > <i class="fa fa-headphones" aria-hidden="true" ></i> Nuktwa Location</a></div></div>
                <div class="row inter-padding"><div class="col-12"><a class="" href="#" > <i class="fa fa-handshake-o" aria-hidden="true" ></i> Nuktwa Humanitaire</a></div></div>
              </div>
            </div>
    
        </div>-->
        <div class="col-md-3 footer-right group-div">
            <span class="titreDiv">Adresse</span> <br /> <br />
            <p>
                Mkazi Comores <br />
                Comores: (00269)-334 30 42 <br />
                France: (00336)-12 13 01 32 <br />
                E-mail: info@nuktwafm.com 
            </p>
        </div>
    
      </div>

    <div class="row div-copyright text-12">
      
        <div class="col-8 footer-left-hacham">
          Nuktwa Fm - La Radio Nuktwa Ha Nuktwa - ASSOCIATION RADIO NUKTWA (SIRET) 84116308200016
        </div>
  
        <div class="col-4 footer-right-hacham">
            <i class="fa fa-copyright" aria-hidden="true"></i> {{dateYear}}, Création : <a href="https://hachamco.fr" target="_blank" class="copyright">Hacham & Co</a>  tous droits reservés. 
        </div>
    
      </div>

</div>
