

<app-list-dedicaces></app-list-dedicaces>


<div class="d-sm-none  ">
  
</div>

<div class="d-none d-md-block d-lg-block d-xl-block separatop-div-top  ">

  <div class="vertical-center text-center text-pub-fixed container cursor " (click)="openPage('/nuktwa-production')" >
      <img src="assets/empire/NuktwaProduction.png" />
  </div>
    
</div>

