
<div class="">

    <div class="row ">
        <div class="col-lg-12 col-12">
            <div class="onoffswitch3">
                <input type="checkbox" name="onoffswitch3" class="onoffswitch3-checkbox" id="myonoffswitch3" checked>
                <label class="onoffswitch3-label" for="myonoffswitch3">
                    <span class="onoffswitch3-inner">
                        <span class="onoffswitch3-active">
                            <marquee class="scroll-text" >
                                <span *ngFor="let dedicace of arrayFlashInfo" class="">
                                    &nbsp; <i class="fa fa-forward" aria-hidden="true"></i> &nbsp; 
                                    <b>{{dedicace.nomPrenom}}</b> : &nbsp;
                                    <span [innerHTML]="dedicace.description"></span> &nbsp;
                                    <span class="text-gray"> ({{dedicace.date_heure}}) </span>
                                </span>
                                
                            </marquee>
                            <span class="onoffswitch3-switch d-none d-md-block d-xl-block cursor ">Dédicaces &nbsp; <img src="assets/imgs/icon/readdedicace.png" width="18" /> </span>

                        </span>
                        <span class="onoffswitch3-inactive"><span class="onoffswitch3-switch">Afficher les dédicaces</span></span>
                    </span>
                </label>
            </div>
        </div>


       <!-- <div class="col-lg-2 col-12"  >
            <div class="onoffswitch3 text-center">

                <label class="bgDedicace cursor" >
                    <img src="assets/imgs/icon/adddedicace.png" width="18" /> Faire une dédicace.
                </label>
            </div>
        </div>-->
    </div>

</div>
