

<div class="background-blanc">
    <div class="row cursor " (click)="playAudio()" >

        <div class="col-2 colorRouge text-center display-icon-play ">
            <span class="material-icons text-h3"> 
                {{ ( audio_playing_encour && audio_playing_encour.playing ) ? 'pause_circle_filled' : 'play_circle_filled'   }}
                 
            </span>
        </div>

        <div class="col-4">
            <img [src]="photoAnnimateur" class="" width="60px" />
        </div>
        <div class="col-6 "  >

            <div class=" row text-12 text-bold colorRouge padding-top-10 "> 
                <span class=""> {{emissionEncours}}  </span>
            </div>
            <div class="row text-12 "> {{ annimateurEncrous }} </div>
            
        </div>
    </div>
</div>
