import { Component, OnInit , Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss']
})
export class PodcastComponent implements OnInit {

  @Input() showInHome = true;
  @Input() isCoran = true;
  @Input() arrayPodcats = [];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }


  readPlayList( playlist ) {

    if (this.isCoran) {
      this.router.navigate([`play-audio/coran/${playlist.id_audio}`]);
    } else {

    }

    // this.router.navigate([page]);
    window.scroll(0,0);
  }

}
