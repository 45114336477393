

<div class="content-application">

    <app-header></app-header>

    


    <div class="content-div-app">
        <app-separator-top></app-separator-top>
        
        <div class="contenu-des-pages contenu-home">

            <div class="row espage-in-title">
                <div class="col-md-12 text-h3 text-bold colorRouge">
                    Nuktwa Production
                </div>
            </div>


            <!-- 
                
                (click)="changeStatus(plt)"

                <div class="form-check">
                        <input type="checkbox" [checked]="plt.ckecked" class="form-check-input" >
                        <label class="form-check-label" for="materialUnchecked">Sélectionner cette voix </label>
                </div>
            
            
            -->
            <div class="row card-pod padding-element cursor" *ngFor="let plt of nosVoixOff; let index_ = index;"  >
            
                <div class="col-4 d-none d-md-block d-xl-block d-lg-block " >
                    <img alt="" class="avatar-content" width="80" height="80" 
                        [src]="plt.image_off"
                    />
                </div>
                <div class="col-10 col-md-7">
                    <span class="text-12 text-bold colorRouge"> {{plt.pseudo_voix}} </span> <br />
                    <span class="text-12 text-prix-voix"> {{plt.prix_off}} </span> <br />
                    <span class="text-12 text-detail-voix"> {{plt.detail_voix}} </span> <br />

                    <span class="text-12 text-italic">
                        Pour plus d'informations, un devis ou une commande, contacter Nuktwa Production en précisant la réference du Voix Off ( {{plt.pseudo_voix}} ) 
                        au (+33) 06 12 13 01 32 
                    </span> <br />

                </div>
    
                <div class=" col-1 colorRouge text-center display-icon-play cursor " (click)="playAudioAndInitPlaylist(plt, index_)" >
                    <span class="material-icons text-h3"> 
                        {{ ( plt.playing ) ? 'stop' : 'play_circle_filled'   }}
                    </span>
                </div>
    
            </div>

            
        </div>

    </div>

    <app-footer></app-footer>
    
</div>
