<div class="row">
    <div class="col-lg-8 col-md-12">

        <div class="row card-pod padding-element cursor" *ngFor="let plt of thePlayList; let index_ = index;" (click)="playAudioAndInitPlaylist(plt, index_)" >
            
            <div class="col-4 d-none d-md-block d-xl-block d-lg-block " >
                <img alt="" class="avatar-content" width="80" height="80" 
                    [src]="plt.image ? plt.image : plt.photo"
                />
            </div>
            <div class="col-10 col-md-7">
                <span class="text-12 text-bold colorRouge"> {{plt.title}} </span> <br />
                <span class="text-12" *ngIf="plt.txt_coran"> {{plt.txt_coran}} <br /></span>
                <span class="text-12" *ngIf="plt.artist"> {{plt.artist}} </span>
            </div>

            <div class=" col-1 colorRouge text-center display-icon-play cursor ">
                <span class="material-icons text-h3"> 
                    {{ ( plt.playing ) ? 'stop' : 'play_circle_filled'   }}
                </span>
            </div>

        </div>

    </div>

    <div class="col-lg-4  d-none d-md-none d-lg-block cursor">
        <img [src]="imageShow" class="image-music" (click)="payWithImage()" />
     </div>

</div>