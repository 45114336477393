import { Component, OnInit } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  dateYear = new Date().getFullYear()
  
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  openPage( page ) {
    this.router.navigate([ page ]);
  }

}
