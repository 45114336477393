import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { PlayAudioComponent } from './pages/play-audio/play-audio.component';
import { ApplicationsComponent } from './pages/applications/applications.component';
import { VideosComponent } from './pages/videos/videos.component';
import { NuktwaProdComponent } from './pages/nuktwa-prod/nuktwa-prod.component';
import { ProgrammeComponent } from './pages/programme/programme.component';
import { NuktwaEmpireComponent } from './pages/nuktwa-empire/nuktwa-empire.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CoranListComponent } from './pages/coran-list/coran-list.component';

const routes: Routes = [

  { path: 'accueil',      component: HomeComponent },
  { path: 'nos-applications',      component: ApplicationsComponent },
  { path: 'nos-videos',      component: VideosComponent },
  { path: 'play-audio/:type/:id',      component: PlayAudioComponent },
  { path: 'nuktwa-production',      component: NuktwaProdComponent },
  { path: 'programme',      component: ProgrammeComponent },
  { path: 'coran',      component: CoranListComponent },
  { path: 'nuktwa-empire',      component: NuktwaEmpireComponent },

  { path: '',   redirectTo: 'accueil', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes , { useHash: true } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
