import { NgModule } from '@angular/core';
import { DateformatService } from './dateformat.service'
import { CommonModule } from '@angular/common';

/*
import { 

  MatButtonModule , 

  MatInputModule ,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatSortModule,
  MatTooltipModule,
  MatListModule,
  MatExpansionModule,
  MatBottomSheetModule,

  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatBadgeModule,

  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatChipsModule,
  MatCheckboxModule,
  MatStepperModule,
  MatTabsModule,
  MatDialogModule

} from '@angular/material';
*/


import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import {  MatSelectModule } from '@angular/material/select';
import {  MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSliderModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule
  ],
  exports: [
    CommonModule,
    MatSliderModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule
  ],
  providers: [DateformatService]
})
export class MaterialAngModule { }
