import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AudioSService } from './audio-s/audio-s.service';
import { GlobalService } from './global/global.service';
import { BackendDirectService } from './backend-direct/backend.service';
import { EventService } from './events/events.service';
import { BackendService } from './backend/backend.service';
import { LoaderService } from './loader/loader.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AudioSService,
    GlobalService,
    BackendDirectService,
    EventService,
    BackendService,
    LoaderService
  ]
})
export class ServicesModule { }
