

<mat-tab-group mat-align-tabs="center" class="tabs-center" 
    [selectedIndex]="indexSelected"
    (selectedIndexChange)="onChangeEvent($event)"
>



    <mat-tab *ngFor="let prog of lesProgrammes" [label]="prog.jour.charAt(0).toUpperCase() + prog.jour.slice(1)">
        
        <div class="container">
        
            <div class="row">
                <div class="col-md-12">
                    <div class="main-timeline2">

                        <div class="timeline" *ngFor="let prg of prog.programme" >
                            <span class="icon material-icons">schedule</span>
                            <a href="#" class="timeline-content">
                                <h3 class="subtitle"> À : {{prg.heure}} </h3>
                                <h3 class="title"> {{prg.nom}} </h3>
                                <h4 class="subtitle"> {{prg.presenterPar}} </h4>
                                <p class="description" [innerHTML]="prg.description" ></p>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </mat-tab>

</mat-tab-group>
