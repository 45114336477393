import { Component, OnInit, ElementRef, ViewChild , Input } from '@angular/core';


import { GlobalService } from '../../services/global/global.service';
import { BackendService } from '../../services/backend/backend.service';
import { EventService } from '../../services/events/events.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-add-dedicaces',
  templateUrl: './add-dedicaces.component.html',
  styleUrls: ['./add-dedicaces.component.scss']
})
export class AddDedicacesComponent implements OnInit {

  retourMessage = { ok: null, ko: null };
  arrayFlashInfo = [];

  @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;
  @ViewChild('box', {static: false}) private boxSend: ElementRef;

  sendEncours = false
  messageRetour = null
  newDedidace = {nomPrenom: "Anonyme", dedi: ""}
  txtNameUser = "";

  constructor(
    private globalService: GlobalService,
    private eventService: EventService,
    private backendService: BackendService
  ) {

    $(function(){
      $("#addClass").click(function () {
        $('#qnimate').addClass('popup-box-on');
      });
                
      $("#removeClass").click(function () {
        $('#qnimate').removeClass('popup-box-on');
      });

    })

    this.eventService.pageDedicaced.subscribe(() => {
      $('#qnimate').addClass('popup-box-on');
      this.openDedicace()
    })

   }

  ngOnInit(): void {
  }

  getDedicaces() {

    this.sendEncours = true

    this.backendService.get( `/dedicaces/list` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat arrayFlashInfo Dedicaces ', resultat );
         this.arrayFlashInfo = resultat.reverse();

         setTimeout(() => {
          this.scrollToBottom()
          this.sendEncours = false
        }, 1000);

      }
     })
     .catch( error => {
      this.sendEncours = false
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })

  }


  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        
    } catch(err) { }                 
  }


  openDedicace(){
    this.getDedicaces()
  }


  addNom(nom) {
    this.newDedidace.nomPrenom = nom != "" && nom.length > 4 ? nom : "Anonyme"
  }


  addDedicace( dedicace ){
    this.newDedidace.dedi = dedicace

    this.addNom( this.txtNameUser )


    if( dedicace != "" && dedicace.length > 10 && !this.sendEncours ) {
      this.sendEncours = true
      this.boxSend.nativeElement.blur()

      let ladedicace = {
        nomPrenom: this.newDedidace.nomPrenom,
        description: this.newDedidace.dedi
      };

      this.backendService.post( `/dedicaces/add` , ladedicace )
      .then( resultat => {
        if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
          this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
        } else {
          console.log( 'resultat arrayFlashInfo Dedicaces ', resultat );

          this.sendEncours = false
          this.messageRetour = "votre dédicace est envoyée avec succès. Merci de votre participation."
          this.boxSend.nativeElement.value = ""

          this.getDedicaces()
          setTimeout(() => {
            this.messageRetour = null
          }, 1000);

        }
      })
      .catch( error => {
        this.sendEncours = false
        this.messageRetour = "Une erreur s'est produite lors de l'envoi de votre dédicace"
      })

    }



  }

}
