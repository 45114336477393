import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../../services/global/global.service';
import { BackendDirectService } from '../../services/backend-direct/backend.service';
import { EventService } from '../../services/events/events.service';
import { LoaderService } from '../../services/loader/loader.service';

import { Subscription } from 'rxjs';
import { AudioSService } from '../../services/audio-s/audio-s.service';


@Component({
  selector: 'app-footer-lecteur',
  templateUrl: './footer-lecteur.component.html',
  styleUrls: ['./footer-lecteur.component.scss']
})
export class FooterLecteurComponent implements OnInit {

  playlistSubscription: Subscription
  playlist_playing_encour : any;
  last_indice = 0
  lecteurState = {
    minEncours: '',
    minEnd: '',
    durationTime: 0,
    currentTime: 0
  };

  progress_bar
  canShowBare = false;


  constructor(
    private globalService: GlobalService,
    private backendDirectService: BackendDirectService,
    private audioSService: AudioSService,
    private eventService: EventService,
    private loaderService: LoaderService
  ) { 
    this.eventService.playingSelected.subscribe( (value) => {
        this.playlist_playing_encour = value.play;
    })
  }

  ngOnInit(): void {
    this.playlist_playing_encour = this.audioSService.getCurrentPlaying();
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.observableAudio()
    }, 1000);
  }


  async playAudio( audio , index){

    console.log('playAudio lecture ::: ' , audio , index )

    if( audio ) {
      const url = audio.url_audio ? audio.url_audio : audio.src ? audio.src : audio.url;
      this.playlist_playing_encour = audio;
      this.playlist_playing_encour.index = index;
      this.playlist_playing_encour.type = this.audioSService.getLectureEnCours().type;

      if( audio.playing ) {
        this.audioSService.pauseAudio()
      } else {

        this.loaderService.show()
        
        console.log('Lecteur lecture ::: ' , audio )
        if( this.audioSService.isPlaying() ) {
          this.audioSService.closePlayer()
        }
        this.audioSService.setAudio(url)
        // this.audioSService.setTypePlaying( 'playlist' )
        this.audioSService.setLectureEnCours( 
          this.audioSService.getLectureEnCours().type,
          this.audioSService.getLectureEnCours().identifiant,
          index 
        );
      }

    }

  }

  nextPlay(){
    console.log( 'nextPlay ' , this.playlist_playing_encour )

    const laPlayList = this.audioSService.getPlaylist();
    this.playlist_playing_encour = this.audioSService.getCurrentPlaying();

    if( this.playlist_playing_encour && ((this.playlist_playing_encour.index + 1) < laPlayList.length ) ) {
      this.audioSService.majStatusPlaylist( this.playlist_playing_encour.index );
      this.playAudio( laPlayList[this.playlist_playing_encour.index + 1], this.playlist_playing_encour.index + 1 );
    } else {
      console.log( 'nextPlay Else ' , this.playlist_playing_encour )
      this.playlist_playing_encour.index = 0
    }
  }


  prevPlay(){

    const laPlayList = this.audioSService.getPlaylist();
    this.playlist_playing_encour = this.audioSService.getCurrentPlaying();

    if( this.playlist_playing_encour && ((this.playlist_playing_encour.index - 1) >= 0 ) ) {
      this.audioSService.majStatusPlaylist( this.playlist_playing_encour.index );
      this.playAudio( laPlayList[this.playlist_playing_encour.index - 1], this.playlist_playing_encour.index - 1 );
    } else {
      this.playlist_playing_encour.index = 0
    }
  }


  isLastPlaying() {
    const laPlayList = this.audioSService.getPlaylist();
    return this.playlist_playing_encour.index === laPlayList.length - 1;
  }

  playInBottom() {
    this.playAudio( this.playlist_playing_encour , this.playlist_playing_encour.index)
  }


  observableAudio(){

    this.playlistSubscription = this.audioSService.getPlayerStatus().subscribe(status => {
      
      console.log('Observable lecture audio in footer ', status )

      this.playlist_playing_encour = this.audioSService.getCurrentPlaying();
      console.log('Observable lecture this.playlist_playing_encour ', this.playlist_playing_encour )

      this.eventService.setPlayingSelect(
        this.playlist_playing_encour,
        this.playlist_playing_encour ? this.playlist_playing_encour.index: 0,
        status
      );

      if ( this.playlist_playing_encour ) {
        switch (status) {
          case 'playing':
            this.last_indice = this.playlist_playing_encour ? this.playlist_playing_encour.index : 0;
            this.playlist_playing_encour = this.audioSService.getCurrentPlaying();
            setTimeout(() => {
              this.canShowBare = true;
              this.loaderService.hide();
              this.displayProgress();
            }, 400);
            break;
          case 'paused':
            if ( this.last_indice === this.audioSService.getLectureEnCours().index ) {
              this.playlist_playing_encour.playing = false
              this.playlist_playing_encour.paused = true
              this.canShowBare = true;
            } else {
              this.playlist_playing_encour.playing = false
              this.playlist_playing_encour.paused = false
              this.canShowBare = true;
            } 
            break;
          case 'waiting':
            this.playlist_playing_encour.playing = true
              this.playlist_playing_encour.paused = false
              this.canShowBare = true;
            break;
          case 'ended':
            this.playlist_playing_encour.playing = false
              this.playlist_playing_encour.paused = false
              this.canShowBare = true;
            this.nextPlay()
            break;
          default:
            this.playlist_playing_encour.playing = false
            this.playlist_playing_encour.paused = false
            this.canShowBare = false;
            break;
        }
      }

    })


    this.audioSService.getTimeRemaining().subscribe( status => {
      this.lecteurState.minEnd = status
      //console.log('getTimeRemaining ' , status)
    })

    this.audioSService.getTimeElapsed().subscribe( (status : any) => {
      this.lecteurState.minEncours = status
      //console.log('getTimeElapsed ' , status)
    })

    this.audioSService.getCurrentTime().subscribe( (status : any) => {
      this.lecteurState.currentTime = Math.floor(status);
    })

    this.audioSService.getDurationTime().subscribe( (status : any) => {
      this.lecteurState.durationTime = Math.floor(status);
    })

  }


  displayProgress() {

    if ( this.playlist_playing_encour.type && this.playlist_playing_encour.type !== 'radio' ) {
      this.progress_bar = document.querySelector("#progress_bar")

      console.log( 'pprogress_bar progress_bar ', this.progress_bar)
  
      let self = this
      this.progress_bar.addEventListener("click", function(e){
  
        
        var progress_bar = e.offsetX;
        var barWidth = e.target.offsetWidth;		
        var audio = self.lecteurState.durationTime;
        let progress = (progress_bar / barWidth) * 100;	
        let currentTime = (progress * audio)/ 100;
        
        self.audioSService.seekAudio( currentTime )
  
      });
    }
    
  }


  closePlayer() {
    this.audioSService.closePlayer();

    setTimeout(() => {
      this.eventService.setPlayingSelect( null, 0, 'stopped' );
    }, 300);
  }



}
