import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {


    initFlashInfo(){
        return [
          {text: "Nuktwa Fm - 100.4 Fm - La Radio Nuktwa Ha Nuktwa - Radio Afro Urbiane des Comores"},
          {text: "Besoin d'un site Web ? Une application Mobile ? Contacter Hacham & Co (+33) 06 58 74 50 24"},
          {text: "Un spot publicitaire ? Nuktwa Production à votre écoute (+33) 06 12 13 01 32"}
        ];
    }

    setVisitor( visitor ){
      if( visitor !== undefined && visitor !== null)
        localStorage.setItem('nuktwa_visitoXztrP', JSON.stringify( visitor ))
    }

    getVisitor(){
      return localStorage.getItem('nuktwa_visitoXztrP') && localStorage.getItem('nuktwa_visitoXztrP') !== "undefined" ? JSON.parse(localStorage.getItem('nuktwa_visitoXztrP')) :  null
    }

    getErrorCode(){
      return [
        0, 401, 403, 404, 422, 500, 501, 503, 301, 302
      ];
    }


}
