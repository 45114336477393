import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialAngModule } from '../shared/matmodule/materialang.module';
import { ServicesModule } from '../services/services.module';
import { LecteurComponent } from './lecteur/lecteur.component';
import { ContentHomeComponent } from './content-home/content-home.component';
import { FlashInfosComponent } from './flash-infos/flash-infos.component';
import { PodcastComponent } from './podcast/podcast.component';
import { FooterLecteurComponent } from './footer-lecteur/footer-lecteur.component'
import { YoutubeVideoComponent } from './youtube-video/youtube-video.component';
import { LoaderComponent } from './loader/loader.component';
import { AddDedicacesComponent } from './add-dedicaces/add-dedicaces.component';
import { ListDedicacesComponent } from './list-dedicaces/list-dedicaces.component';
import { FlashPubComponent } from './flash-pub/flash-pub.component';
import { HomePageFirstComponent } from './home-page-first/home-page-first.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { ProgrammationComponent } from './programmation/programmation.component';
@NgModule({
  declarations: [
    LecteurComponent,
    ContentHomeComponent,
    FlashInfosComponent,
    PodcastComponent,
    FooterLecteurComponent,
    YoutubeVideoComponent,
    LoaderComponent,
    AddDedicacesComponent,
    ListDedicacesComponent,
    FlashPubComponent,
    HomePageFirstComponent,
    AudioPlayerComponent,
    ProgrammationComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    ServicesModule,
    YouTubePlayerModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  exports: [ 
    LecteurComponent , 
    ContentHomeComponent ,
    FlashInfosComponent ,
    PodcastComponent ,
    FooterLecteurComponent,
    YoutubeVideoComponent,
    LoaderComponent,
    ListDedicacesComponent,
    AddDedicacesComponent,
    FlashPubComponent,
    HomePageFirstComponent,
    AudioPlayerComponent,
    ProgrammationComponent
  ]
})
export class SharedModule { }
