

<app-flash-infos [arrayFlashInfos] = "arrayFlashInfo" ></app-flash-infos>

<div class="contenu-home">

    <div class="row">
        <div class="col-12 text-h3 colorRouge padding-bottom-20 text-center">
            En ce moment sur Nuktwa Ha Nuktwa
        </div>
    </div>


    <app-audio-player
    [thePlayList]="thePlayList"
    [imageShow] = "imageShow"
    [typeRead] = "typeRead" 
    [identifiantRead] = "identifiantRead" 
    [playlist_playing_encour] = "playlist_playing_encour"
    > </app-audio-player>



</div>
<br />
