

<div class="content-application">

    <app-header></app-header>


    <div class="content-div-app">
        <app-separator-top></app-separator-top>
        
        <div class="contenu-des-pages contenu-home">

            <div class="row espage-in-title">
                <div class="col-md-12 text-h3 text-bold colorRouge">
                    Grille des programmes de Nuktwa Ha Nuktwa
                </div>
            </div>

            <app-programmation [lesProgrammes]="lesProgramme?.lesProgrammesJour" ></app-programmation>

            
        </div>

    </div>

    <app-footer></app-footer>
    
</div>
