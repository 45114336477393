import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {

  urlIos = "https://itunes.apple.com/us/app/nuktwafm/id1367709523?l=fr&ls=1&mt=8";
  urlAndroid = "https://play.google.com/store/apps/details?id=com.hachamco.nuktwafm";
  constructor() { }

  ngOnInit(): void {
  }


  openStore( app ) {
    if (app === 'ios' ) {
      window.open(this.urlIos, '_blank')
    } else {
      window.open(this.urlAndroid, '_blank')
    }
  }

}
