

 <div class="popup-box chat-popup" id="qnimate">
    <div class="popup-head">
        

        <div class="row">
            <div class="popup-head-left col-10 pull-left">
                <img src="assets/imgs/icon.png" alt="Nuktwa Ha Nuktwa"> Nuktwa Fm
              </div>
              <div class="popup-head-right ">
                  <button mat-icon-button data-widget="remove" id="removeClass" class="chat-header-button pull-right" type="button" >
                      <span class="material-icons">highlight_off</span>
                  </button>
              </div>
        </div>
    </div>

    <div class="popup-messages" #scrollMe >

        <div class="direct-chat-messages" id="popuMessage" >
            
            <div *ngFor="let box of arrayFlashInfo; let i=index; let last = last; " >
                <div class="chat-box-single-line">
                    <abbr class="timestamp">{{box.date_heure}}</abbr>
                </div>
    
                <!-- Message. Default to the left -->
                <div class="direct-chat-msg doted-border">
                    <div class="direct-chat-info clearfix">
                        <span class="direct-chat-name pull-left">{{box.nomPrenom}}</span>
                    </div>
                    <!-- /.direct-chat-info -->
                    <img alt="iamgurdeeposahan" [src]="box?.avatar" class="direct-chat-img"><!-- /.direct-chat-img -->
                    <div class="direct-chat-text">
                        {{box.description}}
                    </div>
                    <div class="direct-chat-info clearfix">
                        <span class="direct-chat-timestamp pull-right">Nuktwa Fm, vous remercie!</span>
                    </div>

                    <div class="direct-chat-info clearfix">
                        <img alt="iamgurdeeposahan" src="assets/imgs/icon.png" class="direct-chat-img big-round">
                        <span class="direct-chat-reply-name">
                            {{box.lu == 1 ? 'Lu' : 'En cours '}}
                        </span>
                    </div>
                    <!-- /.direct-chat-text -->
                </div>
                <!-- /.direct-chat-msg -->
            </div>


        </div>

    </div>

    <div class="popup-messages-footer">
        <input type="text" name="txtNameUser" [(ngModel)]="txtNameUser"  placeholder="Votre nom et prenom " #txtName (keyup.enter)="addNom(txtName.value)" />
        <textarea id="status_message" placeholder="Tapez votre dédicace..." rows="10" cols="40" name="message" #box (keyup.enter)="addDedicace(box.value)" [disabled]="sendEncours" ></textarea>
        <div class="btn-footer">
            <div class="spiner-center" *ngIf="sendEncours"><mat-spinner [diameter]="40" ></mat-spinner></div>
            <div class="message-send" *ngIf="messageRetour">{{messageRetour}}</div>
            Vous aimez ? Dites-le !<br />
            
            <span>Tapez votre dédicace et appuyez sur "Entrée"</span>
            <button class="bg_none pull-right"><span class="material-icons">thumb_up</span></button>
        </div>
    </div>

</div>